import * as React from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { ApiContext } from '../Context';
import { useAuthContext } from '../context/authContext';
import {
  HealthCenterModel,
  HealthcheckType,
  IndividualAssessmentCreateModel,
  MunicipalityModel,
  RespondentRoleAssessmentPlan,
  School,
  SchoolClass,
  User,
} from '../types/models';

//Healtcenter
export const useAllHealthCenters = () => {
  const client = React.useContext(ApiContext);
  return useQuery(['allHealthCenters'], () => client.getAllHealthCenters(), {});
};
export const usePostHealthCenters = () => {
  const client = React.useContext(ApiContext);
  return useMutation(
    ['createHealthCenter'],
    (healthCenter: HealthCenterModel) => client.postHealthCenter(healthCenter),
    {
      onSuccess: async (data) => {
        return await data;
      },
      onError: async (error) => {
        return await error;
      },
    }
  );
};
export const useDeleteHealthCenters = () => {
  const client = React.useContext(ApiContext);
  return useMutation(
    ['deleteHealthCenter'],
    (id: string) => client.deleteHealthCenter(id),
    {
      onSuccess: async (data) => {
        return await data;
      },
      onError: async (error) => {
        return await error;
      },
    }
  );
};

//Municipality
export const useAllMunicipalities = () => {
  const client = React.useContext(ApiContext);
  return useQuery(
    ['allMunicipalities'],
    () => client.getAllMunicipalities(),
    {}
  );
};

export const useMunicipality = (municipalityId: string) => {
  const client = React.useContext(ApiContext);
  return useQuery(
    ['municipality_' + municipalityId, municipalityId],
    () => client.getMunicipality(municipalityId),
    {
      enabled: !!municipalityId,
    }
  );
};
export const usePostMunicipality = () => {
  const client = React.useContext(ApiContext);
  return useMutation(
    ['createMunicipality'],
    (municipal: MunicipalityModel) => client.postMunicipality(municipal),
    {
      onSuccess: async (data) => {
        return await data;
      },
      onError: async (error) => {
        return await error;
      },
    }
  );
};
export const useDeleteMunicipality = () => {
  const client = React.useContext(ApiContext);
  return useMutation(
    ['deleteMunicipality'],
    (id: string) => client.deleteMunicipality(id),
    {
      onSuccess: async (data) => {
        return await data;
      },
      onError: async (error) => {
        return await error;
      },
    }
  );
};

//Respondents
export const useSearchByName = (searchString: string, searchName: string) => {
  const client = React.useContext(ApiContext);
  return useQuery(
    [searchName, searchString],
    () => client.getRespondents(searchString),
    {
      enabled: !!searchString,
    }
  );
};

export const useSearchByNameModal = (
  searchString: string,
  searchName: string
) => {
  const client = React.useContext(ApiContext);
  return useQuery(
    [searchName, searchString],
    () => client.getRespondents(searchString),
    {
      enabled: !!searchString,
    }
  );
};

export const useSearchByNationalId = (
  searchString: string,
  searchName: string
) => {
  const client = React.useContext(ApiContext);
  return useQuery(
    [searchName, searchString],
    async () => await client.getRespondentsByNationalId(searchString),
    {
      enabled: !!searchString,
    }
  );
};

export const useSearchById = (searchString: string, searchName: string) => {
  const client = React.useContext(ApiContext);
  return useQuery(
    [searchName, searchString],
    async () => await client.getRespondentsById(searchString),
    {
      enabled: !!searchString,
    }
  );
};

//School
export const useAllSchools = () => {
  const client = React.useContext(ApiContext);
  return useQuery(['allSchools'], () => client.getAllSchools(), {});
};
export const usePostSchool = () => {
  const client = React.useContext(ApiContext);
  return useMutation(
    ['createSchool'],
    (school: School) => client.postSchool(school),
    {
      onSuccess: async (data) => {
        return await data;
      },
      onError: async (error) => {
        return await error;
      },
    }
  );
};
export const useDeleteSchool = () => {
  const client = React.useContext(ApiContext);
  return useMutation(
    ['deleteSchool'],
    (id: string) => client.deleteSchool(id),
    {
      onSuccess: async (data) => {
        return await data;
      },
      onError: async (error) => {
        return await error;
      },
    }
  );
};

//SchoolClass
export const useAllSchoolClasses = () => {
  const client = React.useContext(ApiContext);
  return useQuery(['allSchoolClasses'], () => client.getAllSchoolClasses(), {});
};
export const usePostSchoolClass = () => {
  const client = React.useContext(ApiContext);
  return useMutation(
    ['createSchoolClass'],
    (schoolClass: SchoolClass) => client.postSchoolClass(schoolClass),
    {
      onSuccess: async (data) => {
        return await data;
      },
      onError: async (error) => {
        return await error;
      },
    }
  );
};
export const useDeleteSchoolClass = () => {
  const client = React.useContext(ApiContext);
  return useMutation(
    ['deleteSchoolClass'],
    (id: string) => client.deleteSchoolClass(id),
    {
      onSuccess: async (data) => {
        return await data;
      },
      onError: async (error) => {
        return await error;
      },
    }
  );
};

//HealthcheckTypes
export const useAllHealthcheckTypes = () => {
  const client = React.useContext(ApiContext);
  return useQuery(
    ['allHealthcheckTypes'],
    () => client.getAllHealthcheckTypes(),
    {}
  );
};

export const usePostHealthcheckType = () => {
  const client = React.useContext(ApiContext);
  const queryClient = useQueryClient();
  return useMutation(
    ['createHealthcheckType'],
    (healthCheckType: HealthcheckType) =>
      client.postHealthcheckTypes(healthCheckType),
    {
      onSuccess: async (data) => {
        return await data;
      },
      onError: async (error) => {
        return await error;
      },
    }
  );
};

export const useDeleteHealthcheckType = () => {
  const client = React.useContext(ApiContext);
  return useMutation(
    ['deleteHealtcheckType'],
    (id: string) => client.deleteHealthcheckTypes(id),
    {
      onSuccess: async (data) => {
        return await data;
      },
      onError: async (error) => {
        return await error;
      },
    }
  );
};

//Users
export const useAllUsers = () => {
  const client = React.useContext(ApiContext);
  return useQuery(['allUsers'], () => client.getAllUsers(), {});
};

//User
export const useUser = () => {
  const client = React.useContext(ApiContext);
  return useQuery(['user'], () => client.getUser(), {});
};

export const usePostUser = () => {
  const client = React.useContext(ApiContext);
  return useMutation(
    ['createUser'],
    (user: User) => client.postUser(user),
    {
      onSuccess: async (data) => {
        return await data;
      },
      onError: async (error) => {
        return await error;
      },
    }
  );
};

export const useDeleteUser = () => {
  const client = React.useContext(ApiContext);
  return useMutation(['deleteUser'], (id: string) => client.deleteUser(id), {
    onSuccess: async (data) => {
      return await data;
    },
    onError: async (error) => {
      return await error;
    },
  });
};

//IndividualAssessment
export const useGetIndividualAssessmentById = (
  id: string,
  searchName: string
) => {
  const client = React.useContext(ApiContext);
  return useQuery(
    [searchName, id],
    async () => await client.getIndividualAssessment(id),
    {
      enabled: !!id,
    }
  );
};


// IndividualAssessments
export const useGetAllIndividualAssessments = () => {
  const client = React.useContext(ApiContext);
  return useQuery(
    ['allIndividualAssessments'],
    async () => await client.getAllIndividualAssessments()
  );
};

export const usePostIndividualAssessment = () => {
  const client = React.useContext(ApiContext);
  return useMutation(
    ['create'],
    (individualAssessment: IndividualAssessmentCreateModel | undefined) =>
      client.postIndividualAssessment(individualAssessment),
    {
      onSuccess: async (data) => {
        return await data;
      },
      onError: async (error) => {
        return await error;
      },
    }
  );
};

// RespondentRoleAssessmentPlan
export const useGetRespondentRoleAssessmentPlan = (id?: string) => {
  const client = React.useContext(ApiContext);
  return useQuery(
    ['getOneRespondentRoleAssessmentPlan', id],
    async () => await client.getRespondentRoleAssessmentPlan(id!),
    { enabled: !!id }
  );
};

export const useGetAllRespondentRoleAssessmentPlan = (
  healthCheckId?: string
) => {
  const client = React.useContext(ApiContext);
  return useQuery(
    ['getAllRespondentRoleAssessmentPlan', healthCheckId],
    async () => await client.getAllRespondentRoleAssessmentPlan(healthCheckId!),
    { enabled: !!healthCheckId }
  );
};

export const usePostRespondentRoleAssessmentPlan = () => {
  const client = React.useContext(ApiContext);
  const queryClient = useQueryClient();
  return useMutation(
    ['createAssessmentPlan'],
    (respondentRoleAssessment: RespondentRoleAssessmentPlan) =>
      client.postRespondentRoleAssessmentPlan(respondentRoleAssessment),
    {
      onSuccess: async (data) => {
        queryClient.refetchQueries(['getAllRespondentRoleAssessmentPlan']);
        return await data;
      },
      onError: async (error) => {
        return await error;
      },
    }
  );
};

export const useDeleteRespondentRoleAssessmentPlan = () => {
  const client = React.useContext(ApiContext);
  const queryClient = useQueryClient();
  return useMutation(
    ['deleteRespondentRole'],
    (id: string) => client.deleteRespondentRoleAssessmentPlan(id),
    {
      onSuccess: async (data) => {
        queryClient.invalidateQueries(['getAllRespondentRoleAssessmentPlan']);
        return await data;
      },
      onError: async (error) => {
        return await error;
      },
    }
  );
};

// Checkware
export const useGetAllCheckwareTreatmentType = () => {
  const client = React.useContext(ApiContext);
  return useQuery(
    ['allTreatmentTypes'],
    async () => await client.getAllCheckwareTreatmentType()
  );
};
export const useGetAllCheckwareRespondentGroup = () => {
  const client = React.useContext(ApiContext);
  return useQuery(
    ['allRespondentGroup'],
    async () => await client.getAllCheckwareRespondentGroup()
  );
};

export const useGetAllCheckwareAssessmentplan = (id: string) => {
  const client = React.useContext(ApiContext);
  return useQuery(
    ['allAssessmentsplan', id],
    async () => await client.getAllCheckwareAssessmentplan(id),
    { enabled: !!id }
  );
};

export const useGetAllCheckwareRespondentRole = () => {
  const client = React.useContext(ApiContext);
  return useQuery(
    ['allRespondentRole'],
    async () => await client.getAllCheckwareRespondentRole()
  );
};

// SessionInfo

export const useLogin = (clicked?: boolean) => {
  const client = React.useContext(ApiContext);
  return useQuery(['login'], () => client.login(), {
    onSuccess: (token) => {
      return token;
    },
    enabled: !!clicked,
  });
};

export const useUserInfo = () => {
  const client = React.useContext(ApiContext);
  return useQuery(['userinfo'], () => client.getInfo());
};

export const useLogout = (clicked?: boolean) => {
  const client = React.useContext(ApiContext);

  return useQuery(['logout'], () => client.logout(), {
    enabled: !!clicked,
  });
};
