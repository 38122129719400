import React, { useEffect } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useToast } from "@chakra-ui/react";
import { useUserInfo } from "../api/hooks";

function PrivateRoute() {
  const { data: userInfo } = useUserInfo();
  const toast = useToast();

  useEffect(() => {
    if (!!!userInfo?.isAuthenticated)
      toast({
        title: "Du må logge inn",
        description: "Siden krever at du er innlogget",
        status: "info",
        isClosable: true,
        position: "top",
      });
  }, []);

  return <>{userInfo?.isAuthenticated ? <Outlet /> : <Navigate to={"/"} />}</>;
}
export default PrivateRoute;
