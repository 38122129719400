import { getInfo, login, logout } from './bffAutentiseringApi';
import {
  getAllCheckwareAssessmentplan,
  getAllCheckwareRespondentGroup,
  getAllCheckwareRespondentRole,
  getAllCheckwareTreatmentType,
} from './bffCheckwareApi';
import {
  deleteHealthCenter,
  getAllHealthCenters,
  postHealthCenter,
} from './bffHealthCenterApi';
import {
  deleteHealthcheckTypes,
  getAllHealthcheckTypes,
  postHealthcheckTypes,
} from './bffHealthCheckTypeApi';
import {
  postIndividualAssessment,
  getIndividualAssessment,
  getAllIndividualAssessments,
} from './bffIndividualAssessmentApi';
import {
  getAllMunicipalities,
  postMunicipality,
  deleteMunicipality,
  getMunicipality,
} from './bffMunicipalityApi';
import {
  getRespondents,
  getRespondentsById,
  getRespondentsByNationalId,
} from './bffRespondentApi';
import {
  deleteRespondentRoleAssessmentPlan,
  getAllRespondentRoleAssessmentPlan,
  getRespondentRoleAssessmentPlan,
  postRespondentRoleAssessmentPlan,
} from './bffRespondentAssessmentPlan';
import { deleteSchool, getAllSchools, postSchool } from './bffSchoolApi';
import {
  getAllSchoolClasses,
  postSchoolClass,
  deleteSchoolClass,
} from './bffSchoolClassApi';
import {
  getAllTreatmentTypes,
  postTreatmentType,
  deleteTreatmentType,
} from './bffTreatmentTypeApi';
import { getAllUsers, getUser, postUser, deleteUser } from './bffUserApi';

export default {
  getAllHealthCenters,
  postHealthCenter,
  deleteHealthCenter,

  getAllHealthcheckTypes,
  postHealthcheckTypes,
  deleteHealthcheckTypes,

  getIndividualAssessment,
  getAllIndividualAssessments,
  postIndividualAssessment,

  getAllMunicipalities,
  getMunicipality,
  postMunicipality,
  deleteMunicipality,

  getRespondents,
  getRespondentsById,
  getRespondentsByNationalId,

  getAllSchools,
  postSchool,
  deleteSchool,

  getAllSchoolClasses,
  postSchoolClass,
  deleteSchoolClass,

  getAllTreatmentTypes,
  postTreatmentType,
  deleteTreatmentType,

  getAllUsers,
  getUser,
  postUser,
  deleteUser,

  getRespondentRoleAssessmentPlan,
  getAllRespondentRoleAssessmentPlan,
  postRespondentRoleAssessmentPlan,
  deleteRespondentRoleAssessmentPlan,

  getAllCheckwareTreatmentType,
  getAllCheckwareRespondentGroup,
  getAllCheckwareAssessmentplan,
  getAllCheckwareRespondentRole,

  login,
  logout,
  getInfo,
};
