import React, { useEffect, useState } from 'react';
import { Box, Button, Heading, Stack, Text } from '@chakra-ui/react';
import SearchForm from '../components/SearchForm';
import { QuestionIcon } from '@chakra-ui/icons';
import {
  useAllHealthcheckTypes,
  useGetAllRespondentRoleAssessmentPlan,
  useAllSchools,
  useAllUsers,
  useGetIndividualAssessmentById,
  usePostIndividualAssessment,
  useSearchByName,
  useUser,
} from '../api/hooks';
import SearchResults from '../components/SearchResults';
import StandardSpinner from '../components/StandardSpinner';
import ChosenRespondent from '../components/ChosenRespondent';
import Respondents from '../components/Respondents';
import AddRespondents from '../components/AddRespondents';
import {
  Respondent,
  IndividualAssessmentCreateModel,
  RespondentRoleTypes,
} from '../types/models';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { env } from '../env';
import { formatDateOnly, getAgeNumber } from '../utilities/HelperFunctions';
import GenericDropdown from '../components/GenericDropdown';
import { InfoModal } from '../components/InfoModal';
import Cookies from 'universal-cookie';
import { Navigate, useSearchParams } from 'react-router-dom';

const baseUrl = env.REACT_APP_BASE_URL || '';

function IndividualAssessment() {
  const [search, setSearch] = useState('');
  const [chosenRespondent, setChosenRespondent] = useState<Respondent>();
  const [additionalRespondents, setAdditionalRespondents] =
    useState<Respondent[]>();
  const [startDate, setStartDate] = useState(new Date());
  const [school, setSchool] = useState<string | null>(null);
  const [healthCheckType, setHealthCheckType] = useState<string>('');
  const [healthCenter, setHealthCenter] = useState<string>('');
  let { data: user } = useUser();
  const [clinician, setClinician] = useState<string>(user?.id || '');
  const [createModal, setCreateModal] = useState<{
    show: boolean;
    text: string;
    showHeaderIcon: boolean;
  }>();
  const [assessmentId, setAssessmentId] = useState('');
  const [assessmentCreate, setAssessmentCreate] =
    useState<IndividualAssessmentCreateModel>();
  const [guid, setGuid] = useState('');
  const [searchParams, setSearchParams] = useSearchParams();

  const cookies = new Cookies();
  const cookieName = 'individualAssessmentCookie';
  const individualCookie = cookies.get(cookieName);

  const [infoModalModel, setInfoModalModel] = useState<{
    show: boolean;
    type: string;
  }>();

  let { data: individualAssessment, isLoading: isLoadingAssessment } =
    useGetIndividualAssessmentById(guid, `getIndividualById_ ${guid}`);

  let { data: respondents, isFetching } = useSearchByName(
    search,
    'mainIndividualSearch'
  );

  let { data: schools } = useAllSchools();
  let { data: healthcheckTypes } = useAllHealthcheckTypes();
  let { data: respondentRoleAssessmentPlans } =
    useGetAllRespondentRoleAssessmentPlan(healthCheckType);
  let { data: users } = useAllUsers();
  let { isLoading, mutate } = usePostIndividualAssessment();

  useEffect(() => {
    //Set default clinician to logged in user
    setClinician(user?.id ?? '');
  }, []);

  useEffect(() => {
    //Set default healthCenter to user's currentHealthCenterId
    setHealthCenter(user?.currentHealthCenterId ?? '');
  }, []);

  useEffect(() => {
    if (searchParams.get('id') !== null) setGuid(searchParams.get('id') ?? '');
    else if (individualCookie) setGuid(individualCookie.id);

    if (!!!isLoadingAssessment && individualAssessment) {
      setChosenRespondent(individualAssessment.children[0]);
      try {
        let arrayOfResp: Respondent[] = [];
        individualAssessment.parents.map((parent) => {
          parent.isDefaultParent = true;
          arrayOfResp.push(parent);
        });
        individualAssessment.respondents.map((respondent) => {
          if (
            !!!arrayOfResp.find((x) => x.id === respondent.id) &&
            respondent.respondentRoleType !== 'Child'
          )
            arrayOfResp.push(respondent);
        });
        setAdditionalRespondents(arrayOfResp);

        setStartDate(
          individualAssessment.assessmentDate
            ? new Date(individualAssessment.assessmentDate)
            : new Date(Date.now())
        );
        setSchool(individualAssessment.schoolId ?? null);
        setHealthCheckType(individualAssessment.healthCheckTypeId ?? '');
        setClinician(individualAssessment.clinicianId ?? '');
        setHealthCenter(individualAssessment.healthCenterId ?? '');
      } catch {
        clearState(false);
        console.log(`Loading cookie failed`);
      }
    }
  }, [isLoadingAssessment]);

  const clearState = (deleteCookie: boolean = true) => {
    setSearch('');
    setChosenRespondent(undefined);
    setAdditionalRespondents(undefined);
    setStartDate(new Date());
    setSchool(null);
    setHealthCheckType('');
    setClinician('');
    setHealthCenter('');
    setCreateModal({ show: false, text: '', showHeaderIcon: true });

    if (individualCookie && deleteCookie) {
      cookies.remove(cookieName);
    }
  };

  const setIndividualCookie = (id: String) => {
    cookies.set(cookieName, {
      id: id,
    });
  };

  const setCurrentChosenRespondent = async (respondent: Respondent) => {
    respondent.isEnabled = true;
    setChosenRespondent(respondent);

    const defaultParentsPromises = respondent?.parents.map(
      async (parent: string, i: number) => {
        return await fetch(
          `${baseUrl}/api/respondent/search/api/respondent/search/${parent}`,
          {
            credentials: 'include',
            headers: {
              Accept: 'application/json',
            },
          }
        )
          .then((response) => response.json())
          .then(async (data: Respondent) => {
            data.respondentRoleType = 'parent';
            data.isDefaultParent = true;
            data.isEnabled = true;
            return data;
          });
      }
    );
    const defaultParents = await Promise.all(defaultParentsPromises);

    setAdditionalRespondents([
      ...(additionalRespondents ?? []),
      ...defaultParents,
    ]);
  };

  const removeRespondent = (respondent: Respondent) => {
    let array =
      additionalRespondents?.filter((x) => x.id !== respondent.id) ?? [];

    setAdditionalRespondents([...array]);
  };

  const addRespondent = (respondent: Respondent) => {
    let array = additionalRespondents ?? [];
    respondent.isEnabled = false;
    respondent.assessmentStatus = "Planned";

    if (array?.find((x) => x.nationalIdNumber == respondent.nationalIdNumber)) {
      setCreateModal({
        show: true,
        text: 'Respondent kan ikke legges til flere ganger.',
        showHeaderIcon: true,
      });
    } else {
      array?.push(respondent);
      setAdditionalRespondents([...array]);
    }
  };

  const updateRespondentList = (respondent: Respondent) => {
    if (additionalRespondents != undefined) {
      let index = additionalRespondents.findIndex(
        (r) => r.id === respondent.id
      );
      let array = additionalRespondents;
      array[index] = respondent;
      setAdditionalRespondents([...array]);
    }
  };

  const filterEnabledRespondents = (respondents: Respondent[] | undefined) => {
    if (!respondents) return undefined;
    const filterByIsEnabled = (respondent: Respondent) => {
      return respondent.isEnabled;
    };
    let filteredRespondents = respondents.filter(filterByIsEnabled);
    return filteredRespondents;
  };

  const getAssessmentRequest = (status: string) => {
    if (!!!chosenRespondent) return undefined;
    let respondents = [chosenRespondent, ...(additionalRespondents ?? [])];
    let assessment: IndividualAssessmentCreateModel = {
      id:
        assessmentId !== ''
          ? assessmentId
          : '00000000-0000-0000-0000-000000000000',
      name: chosenRespondent?.name ?? '',
      respondents: [...(filterEnabledRespondents(respondents) ?? [])],
      healthCheckTypeId: healthCheckType,
      schoolId: school,
      clinicianId: clinician,
      assessmentDate: formatDateOnly(startDate),
      healthCenterId: user?.currentHealthCenterId!,
      assessmentStatus: status,
    };

    return assessment;
  };

  const validateRespondentAssessmentPlans = (
    assessment: IndividualAssessmentCreateModel
  ) => {
    var result = true;
    if (respondentRoleAssessmentPlans == undefined) {
      setInfoModalModel({
        show: true,
        type: 'Type kontroll inneholder ikke noen roller. Kontakt administrator eller velg en annen type kontroll.',
      });
      result = false;
    }
    let parents = assessment.respondents?.filter(
      (x) => x.respondentRoleType === 'parent'
    );
    let availableRespondentRoleIds = respondentRoleAssessmentPlans?.map(
      (respondentRoleAssessmentPlan) =>
        respondentRoleAssessmentPlan.respondentRoleId.toString()
    );
    assessment.respondents.forEach((respondent) => {
      if (
        respondent.respondentRoleType.toLowerCase() === 'child' &&
        respondent.isEnabled &&
        !availableRespondentRoleIds?.includes(
          RespondentRoleTypes.child.toString()
        )
      ) {
        setInfoModalModel({
          show: true,
          type: 'Type kontroll inneholder ikke rollen "Barnet". Kontakt administrator eller velg en annen type kontroll.',
        });
        result = false;
      }
      if (
        parents &&
        parents.length >= 1 &&
        respondent.respondentRoleType.toLowerCase() === 'parent' &&
        respondent.isEnabled &&
        !availableRespondentRoleIds?.includes(
          RespondentRoleTypes.parent1.toString()
        )
      ) {
        setInfoModalModel({
          show: true,
          type: 'Type kontroll inneholder ikke rollen "Foresatt 1". Kontakt administrator eller velg en annen type kontroll.',
        });
        result = false;
      }
      if (
        parents &&
        parents.length >= 2 &&
        respondent.respondentRoleType.toLowerCase() === 'parent' &&
        respondent.isEnabled &&
        !availableRespondentRoleIds?.includes(
          RespondentRoleTypes.parent2.toString()
        )
      ) {
        setInfoModalModel({
          show: true,
          type: 'Type kontroll inneholder ikke rollen "Foresatt 2". Kontakt administrator eller velg en annen type kontroll.',
        });
        result = false;
      }
      if (
        respondent.respondentRoleType.toLowerCase() === 'kindergartenteacher' &&
        respondent.isEnabled &&
        !availableRespondentRoleIds?.includes(
          RespondentRoleTypes.kindergartenteacher.toString()
        )
      ) {
        setInfoModalModel({
          show: true,
          type: 'Type kontroll inneholder ikke rollen "Barnehagelærer". Kontakt administrator eller velg en annen type kontroll.',
        });
        result = false;
      }

      if (
        respondent.respondentRoleType.toLowerCase() === 'teacher' &&
        respondent.isEnabled &&
        !availableRespondentRoleIds?.includes(
          RespondentRoleTypes.teacher.toString()
        )
      ) {
        setInfoModalModel({
          show: true,
          type: 'Type kontroll inneholder ikke rollen "Lærer". Kontakt administrator eller velg en annen type kontroll.',
        });
        result = false;
      }
    });
    return result;
  };

  const handleMutate = (assessment: IndividualAssessmentCreateModel) => {
    var validRespondentRoles = validateRespondentAssessmentPlans(assessment);
    if (validRespondentRoles) {
      mutate(assessment, {
        onSuccess: (data) => {
          setCreateModal({
            show: true,
            text:
              assessment.assessmentStatus === 'Draft'
                ? 'Kartlegging lagret. '
                : 'Kartlegging sendt inn. ',
            showHeaderIcon: false,
          });
          if (assessment.assessmentStatus === 'Draft') {
            setAssessmentId(data.id);
            setIndividualCookie(data.id);
          }
        },
        onError(error: any, variables, context) {
          setCreateModal({
            show: true,
            text:
              (assessment.assessmentStatus === 'Draft'
                ? 'Lagre kartlegging feilet. '
                : 'Innsending av kartlegging feilet. ') + error.message,
            showHeaderIcon: true,
          });
        },
      });
    }
  };

  const isSubmitDisabled = () => {
    if (
      healthCheckType &&
      healthCheckType.length > 0 &&
      clinician &&
      clinician.length > 0
    )
      return false;
    return true;
  };

  if (!!!user?.id) return <Navigate to="/" />;

  return (
    <Box p="9">
      <Heading size="xl" mb="16">
        Individuell kartlegging
        <QuestionIcon color="blue.500" ml={4} pb={1} />
      </Heading>
      <SearchForm
        heading="Søk opp barn fra personregister"
        placeholder="Søk på navn eller fødselsnummer (11 siffer)"
        onSubmit={setSearch}
      />
      {isFetching && <StandardSpinner />}
      {!chosenRespondent && respondents && (
        <SearchResults
          results={respondents?.filter(
            (respondent) => getAgeNumber(new Date(respondent.birthDate))! <= 20
          )}
          setChosenRespondent={setCurrentChosenRespondent}
          onCancel={() => clearState()}
          modal={false}
        />
      )}

      {chosenRespondent && (
        <>
          <ChosenRespondent chosenRespondent={chosenRespondent} />
          <Respondents
            childRespondent={chosenRespondent}
            additionalRespondents={additionalRespondents}
            removeRespondent={removeRespondent}
            updateRespondentList={updateRespondentList}
          />
          <AddRespondents addRespondent={addRespondent} />
          {
            <>
              <GenericDropdown
                heading="Type kontroll*"
                placeHolder="Velg type kontroll"
                items={healthcheckTypes ?? []}
                onChange={setHealthCheckType}
                value={healthCheckType}
              />
              <GenericDropdown
                heading="Skole barnet går på"
                placeHolder="Velg skole"
                items={schools ?? []}
                onChange={setSchool}
                value={school}
              />
              <GenericDropdown
                heading="Avtale med helsesykepleier*"
                placeHolder="Velg helsesykepleier"
                items={users ?? []}
                onChange={setClinician}
                value={clinician}
              />
              <Heading size="xs" mt={5} mb={1}>
                Dato for utsendelse av kartlegging*
              </Heading>
              <Box width={'33%'} border={'1px'}>
                <>
                  <DatePicker
                    selected={startDate}
                    openToDate={undefined}
                    onChange={(date: Date) => setStartDate(date)}
                    dateFormat="dd.MM.yyyy"
                    isClearable={true}
                  />
                </>
              </Box>

              <Box mt={'8'}>
                <Stack direction={['column', 'row']} spacing={'10px'}>
                  <Button
                    variant={'outline'}
                    colorScheme="blue"
                    isLoading={isLoading}
                    isDisabled={isSubmitDisabled()}
                    onClick={async () => {
                      let assessment = getAssessmentRequest('Draft');
                      if (assessment) {
                        setAssessmentCreate(assessment);
                        handleMutate(assessment);
                      }
                    }}
                  >
                    Lagre utkast
                  </Button>
                  <Button
                    ml="3"
                    colorScheme={'red'}
                    onClick={() => {
                      clearState();
                    }}
                  >
                    Slett utkast
                  </Button>
                  <Button
                    ml="3"
                    isLoading={isLoading}
                    isDisabled={isSubmitDisabled()}
                    onClick={async () => {
                      let assessment = getAssessmentRequest('Planned');
                      if (assessment) {
                        setAssessmentCreate(assessment);
                        handleMutate(assessment);
                      }
                    }}
                  >
                    Start kartlegging
                  </Button>
                </Stack>
              </Box>

              <Text mt="10" fontSize="sm">
                *Obligatoriske felt
              </Text>

              <InfoModal
                show={createModal?.show ?? false}
                onClose={() => {
                  setCreateModal({
                    show: false,
                    text: '',
                    showHeaderIcon: true,
                  });
                  if (assessmentCreate?.assessmentStatus === 'Planned')
                    clearState();
                }}
                closeButtonText="Lukk"
                text={createModal?.text ?? ''}
                showHeaderIcon={createModal?.showHeaderIcon ?? true}
              />

              <InfoModal
                show={infoModalModel?.show ?? false}
                onClose={setInfoModalModel}
                closeButtonText="Lukk"
                text={infoModalModel?.type ?? ''}
                showHeaderIcon={true}
              />
            </>
          }
        </>
      )}
    </Box>
  );
}

export default IndividualAssessment;
