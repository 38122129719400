import React from 'react'
import * as Chakra from '@chakra-ui/react';

export const AssessmentStatusFilter = () => {
  return (
    <Chakra.Grid templateColumns='repeat(3, 1fr)' rowGap={6}>
        <Chakra.Box mr={12}>
            <Chakra.FormLabel fontWeight='600'>Status</Chakra.FormLabel>
            <Chakra.Select>

            </Chakra.Select>                    
        </Chakra.Box>
        <Chakra.Box mr={12}>
            <Chakra.FormLabel fontWeight='600'>Type kartlegging</Chakra.FormLabel>
            <Chakra.Select>

            </Chakra.Select>                    
        </Chakra.Box>
        <Chakra.Box mr={12}>
            <Chakra.FormLabel fontWeight='600'>Ansvarlig helsesykepleier</Chakra.FormLabel>
            <Chakra.Select>

            </Chakra.Select>                    
        </Chakra.Box>

        <Chakra.Box mr={12}>
            <Chakra.FormLabel fontWeight='600'>Skole</Chakra.FormLabel>
            <Chakra.Select>

            </Chakra.Select>                    
        </Chakra.Box>
        <Chakra.Box mr={12}>
            <Chakra.FormLabel fontWeight='600'>Klasse</Chakra.FormLabel>
            <Chakra.Select>

            </Chakra.Select>                    
        </Chakra.Box>
        <Chakra.Box mr={12}>
            <Chakra.FormLabel fontWeight='600'>Skoleår</Chakra.FormLabel>
            <Chakra.Select>

            </Chakra.Select>                    
        </Chakra.Box>

    </Chakra.Grid>
  )
}
