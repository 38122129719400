import React from 'react'
import { useGetAllIndividualAssessments } from '../api/hooks';

interface TableRows {
    id: string;
    person: { name: string, age: string, SSN: string };
    status: string;
    date: string
}

export const AssessmentStatusTable = () => {

    const {data: assessmentsStatus, isLoading} = useGetAllIndividualAssessments();

    return (
        <div>AssessmentStatusTable</div>
    )
}
