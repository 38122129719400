import { QuestionIcon } from "@chakra-ui/icons";
import { Heading, Box, VStack, Text } from "@chakra-ui/react";
import React from "react";
import { Navigate } from "react-router-dom";
import { AssessmentStatusFilter } from "../components/AssessmentStatusFilter";
import { AssessmentStatusTable } from "../components/AssessmentStatusTable";
import FeatureDisabled from "../components/FeatureDisabled";
import { env } from "../env";
import { useUserInfo } from "../api/hooks";

const AssessmentStatus = () => {
  const { data: userInfo } = useUserInfo();

  function renderAssessmentStatusForm() {
    if (!!!userInfo?.isAuthenticated) return <Navigate to="/" />;
    if (env.REACT_APP_FEATURE_ASSESSMENT_STATUS === "false")
      return <FeatureDisabled />;

    return (
      <Box p="9">
        <Heading size="xl" mb="16">
          Individuell Kartlegging
        </Heading>
        <Box w="70%">
          <AssessmentStatusFilter />
        </Box>
        <AssessmentStatusTable />
      </Box>
    );
  }

  return (
    <VStack spacing="24px" align={"flex-start"} p="9">
      <Heading size="xl" mb="8">
        Status kartlegging <QuestionIcon color="blue.500" ml={2} pb={1} />
      </Heading>

      {renderAssessmentStatusForm()}
    </VStack>
  );
};

export default AssessmentStatus;
